import React, { useState, useEffect,  } from "react";
import logoImg from "./Images/RM - Idan-03 1.svg";
import logoTxt from "./Images/Request Mechanic.png";
import supercharge from "./Images/Supercharge Ignite Transfrom.png";
import ignite from "./Images/Ignite.png";
import Transform from "./Images/Transform.png";
import Showroom from "./Images/showroom.png";
import ShowroomCar from "./Images/showroomcar.png";
import phonePic from "./Images/Loan.png";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiArrowRight,
  FiArrowUpRight,
} from "react-icons/fi";
import footerLogo from "./Images/Frame 1000000874.png";
import cultureNames from "./Images/Group-84.png";
import featuresPhone from "./Images/phone.png";
import featuresLock from "./Images/lock.png";
import featuresSccop from "./Images/sccop.png";
import "bootstrap/dist/css/bootstrap.min.css";
import TidioChat from "./Components/TidioChat";
import { Helmet } from "react-helmet";
import MobileApp from "./Images/mobileapp.png";
import NavListHeader from "./Components/DynamicHeader";
import MobileMenu from "./Components/mobileMenu";
import Faq from "./Components/Faq";
import CarDrive from "./Components/driveAnimation";
import { useNavigate} from "react-router-dom";
import { AndroidDownloadButton, AppleStoreButton } from "./Components/downloadButtons";
import { Link } from "react-router-dom";
import { loginOwner, rememberMeLogin, resendOtp } from "./Components/store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { TnC } from "./Components/terms-privacy-conditions/terms-conditions/tnc";
import { CustomerTnC } from "./Components/terms-privacy-conditions/terms-conditions/customerTnC";
import { PP } from "./Components/terms-privacy-conditions/privacy-policy/pp";
import { CustomerPP } from "./Components/terms-privacy-conditions/privacy-policy/customer-pp";
import { TermsModal } from "./Components/modal/termsModal";
import mixpanel from 'mixpanel-browser';
import Button from '@mui/material/Button'; // Ensure the correct library and installation
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons

import {LinearProgress} from "@mui/material"

const mixpanel_token = "fd917c03a5beff421e281e3937e2f7a1"
mixpanel.init("fd917c03a5beff421e281e3937e2f7a1", {debug: true, track_pageview: "full-url", persistence: 'localStorage'});
const contractTypeConstant = {
  Mechanic:"mechanic",
  Customer:"Customer"
}

const isProd  = (window.location.hostname === "requestmechanic.com"); 



const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [contractType,setContractType] = useState('');
  const navigate = useNavigate();
  const [deferredPrompt,setDeferredPrompt] = useState();
  const [isDownloaded,setIsDownloaded] = useState();
  const [showPwaInstallBanner,setshowPwaInstallBanner] = useState(false);
  const [showDownloadDiag,setshowDownloadDiag] = useState(false);
  const [isNotEligibile,setisNotEligibile] = useState(false);
  const [bannerHasDisplayed,setBannerHasDispalyed] = useState(false);
  const [hasPwaSupport,setHasPwaSupport] = useState(false);
  const [support_check_timeout,set_support_check_timeout] = useState(false);

  const [loginEmail, setLoginEmail] = useState(''); //useState email for the rememberMe functionality
  const [accessToken, setAccessToken] = useState();

  const dispatch = useDispatch();  //Initialize useDispatch hook

  const openModal = (mType,contractType) => {
    setIsModalOpen(true);
    setModalType(mType);
    setContractType(init=>contractType)

    if (mType === "pp") {
      navigate("#privacy-policy");
    } else if (mType === "tnc") {
      navigate("#terms-and-condition");
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType("");
    setContractType(init=>"")
    navigate(window.location.pathname);
  };

  useEffect(function(){
    if(process.env.REACT_APP_ENV === "development"){
        console.log("you are currently in developement mode",`${!isProd}`);
    }else{
      console.log("bobo yi where you dey like this ?-_-!");
    }
  },[])
  
  useEffect(() =>{
    const userEmail = localStorage.getItem('userEmail');
    const access = localStorage.getItem("authAccessToken");
    const rememberMeStatus = localStorage.getItem("rememberMe");
    const accountTypeStatus = localStorage.getItem('accountType');


    if (access) {

      if (rememberMeStatus === "true") {
        if (accountTypeStatus === 'mechanic'){
          navigate("/mechanic-dashboard");
        }
        else if (accountTypeStatus==='owner') {
          navigate("/mechanic-near-me");
        }
      } 
    }
    
  }, [])

  useEffect(function(){
    if ('getInstalledRelatedApps' in window.navigator) {
      console.log("inside getInstalledRelatedApps conditional");
      navigator.getInstalledRelatedApps().then(function(response){
        console.log("response is ",response);
        response.forEach((app) => {
          if (app.url == "https://reqmechstaging.web.app/manifest.json") {
            setIsDownloaded(init=>true);
          }
          console.log("inside related apps function");
          console.log(app.platform, app.url);
        });
      })
    }
    window.addEventListener('beforeinstallprompt',function(e){
      e.preventDefault();
      setDeferredPrompt(init=>e);
      setHasPwaSupport(init=>true);
      // setshowPwaInstallBanner(init=>true);
    })
  },[])
  useEffect(function(){
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      setIsDownloaded(init=>true);
      mixpanel.track('home page Activity',{action:`PWA Used`});
    }
    window.addEventListener("appinstalled", () => {
      mixpanel.track('home page Activity',{action:`PWA Installed`});
      setIsDownloaded(init=>true);
    });
  },[])

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#privacy-policy") {
      openModal("pp");
    } else if (hash === "#terms-and-condition") {
      openModal("tnc");
    }
  }, []); // Run only once on component mount

  const images = [
    { id: 1, src: supercharge },
    { id: 2, src: ignite },
    { id: 3, src: Transform },
  ];


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);

    return () => clearInterval(timer);
  }, [images.length]);

  useEffect(()=>{
    console.log('Home Page Tracked');
    mixpanel.track('Home Page visit');
  },[])

  const validateEmail = (email) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the provided email against the regex
    return emailRegex.test(email);
  };

  const handleJoinWaitlist = async () => {
    mixpanel.track('home page Activity',{action:`clicked on body request now button`});
    
    if (email) {
      // Check if the email is valid
      if (!validateEmail(email)) {
        setErrorMessage("Please enter a valid email address.");
        return;
      }

      try {
        const response = await fetch(
          "https://waitlist.requestmechanic.com/waitlist/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
          }
        );

        if (response.ok) {
          setEmail(""); // Clear the email input
          alert("Thank you! You have been added to the waitlist.");
        } else {
          const responseData = await response.json(); // Parse response data as JSON

          if (responseData.message === "Email already exists in waitlist") {
            alert("Email already exists in waitlist");
          } else {
            alert("Email already exists in waitlist", responseData);
            console.error("An error occurred. Please try again later.");
          }
        }
      } catch (error) {
        alert("Error adding email to waitlist:", error);
        console.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="App">
      <script
        src="//code.tidio.co/ryro9pn1sskra1afhjvvi8ws0kxshcwb.js"
        async
      ></script>
      <Helmet>
        {/* Google tag (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-X2TRNVM7K2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-X2TRNVM7K2');
          `}
        </script>
        <link rel="canonical" href="https://www.requestmechanic.com/" />
        {/* <meta
      name="description"
      content="A platform that connects automobile users to the nearest automobile experts."
      data-rh="true"
    /> */}
      </Helmet>
      {(showDownloadDiag) && <DownlaodDialouge  setshowDownloadDiag={setshowDownloadDiag} support_check_timeout={support_check_timeout} />}
      <nav className="fixed z-50 top-0 left-0 w-[100vw] bg-black px-[25px] sm:px-[50px] md:px-[100px] py-[30px] mb-[150px] flex flex-row items-center justify-between border-b border-b-gray-900">
        <div className="flex gap-10">
          {/* NAV LOGO */}
          <div className="flex gap-1">
            <img
              className="object-contain h-8"
              src={logoImg}
              alt="Request Mechanic"
            />
            <img className="object-contain h-8" src={logoTxt} alt="" />
          </div>
          {/* NAV LINKS */}
          {/* <div className="hidden md:flex items-center gap-4">
            {navlist.map((nav) => {
                return <a key={nav.title} href={nav.link} className="text-gray-400 hover:text-white text-sm no-underline">{nav.title}</a>
            })}
          </div> */}
          <NavListHeader />
        </div>
        {/* NAV CTA */}
        <Link 
        onClick={()=>{ mixpanel.track('home page Activity',{action:`Clicked on Request Button`})}}
          to={"/onboarding"}
          className="request-nav-button flex items-center gap-2 text-black text-sm py-2 px-3 w-fit flex-grow-0"
        >
          Request Now
          <FiArrowRight
            className="FiArrowRight"
            style={{ color: "black", height: "20px", width: "20px" }}
          />
        </Link>
        <MobileMenu />
      </nav>

      <main className="grid grid-cols-1 sm:grid-cols-2 gap-24 mt-32 items-center">
        <div className="main-grid1 flex flex-col gap-4">
          <div className="grid1-txt">
            <a
              href="#"
              className="app-is-live-div no-underline flex flex-row gap-2 items-center rounded-full p-2 "
            >
              <span className="bg-green-600 text-center text-white text-xs py-1 px-2 rounded-full">
                NEW
              </span>
              <span className="app-is-live-text">
              The Beta version of our Mobile Application is live
              </span>
              <svg
                className="btn-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
              </svg>
            </a>
            <p className="text-white font-[DM Sans] xs:text-center md:text-left text-3xl md:text-5xl lg:text-6xl leading-tight">
            Your Go-To Solution for Finding a Mechanic Near You. Keep going with{" "}
              <span className="text-[#6BFDCB] sm:text-3xl md:text-4xl lg:text-6xl italic">
                {" "}
                RequestMechanic
              </span>
            </p>
            {/* <p className="hero-small-text">
              We're not just fixing cars, we're orchestrating a symphony of solutions for your ride. Your car, our expertise, and a touch
              of automotive wizardry.
            </p> */}
            {/* <p className="text-slate-500 mt-[23.1px]">
              We're not just fixing cars, we're orchestrating a symphony of solutions for your ride. Your car, our expertise, and a touch of automotive wizardry.
              </p> */}
          </div>

          <div className="input-button-div flex flex-col items-center sm:flex-row gap-4">
  <Link to={"/onboarding"} className="no-underline">
    <button
      id="join-button"
      type="submit"
      className="request-now-button sm:w-auto flex items-center justify-center hover:bg-gray-700 hover:text-white text-sm font-medium"
      onClick={handleJoinWaitlist}
    >
      Request Now
    </button>
  </Link>

  {/* Change mailto link to WhatsApp link */}
  <a
    href="https://wa.me/message/ED4HBG5GK5KPM1" // Replace with your WhatsApp number
    target="_blank" // Open WhatsApp link in new tab
    rel="noopener noreferrer"
    className="no-underline"
  >
    <button
      id="contact-us-button"
      type="button"
      className="contact-us-button sm:w-auto flex items-center justify-center text-sm font-medium"
      onClick={() => {
        mixpanel.track('home page Activity', { action: `clicked on contact us button` });
      }}
    >
      {/* Add WhatsApp icon beside the Contact Us text */}
      <FaWhatsapp className="mr-2" /> 
      Contact Us
    </button>
  </a>
</div>
          <div id="download" className="w-full flex flex-col landscape:flex-row gap-4">

            {!isDownloaded && <AndroidDownloadButton support_check_timeout = {support_check_timeout} set_support_check_timeout={set_support_check_timeout} hasPwaSupport={hasPwaSupport} isNotEligibile={setisNotEligibile} diagCont = {setshowDownloadDiag} deferredPrompt={deferredPrompt} setDeferredPrompt={setDeferredPrompt} />}
            <AppleStoreButton/>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>

        <CarDrive />
        {/* <div className="relative grid grid-cols-2 gap-4 border-1 border-dashed p-4 rounded">
          <img src={heroImg1} alt="" className="w-full h-full rounded object-contain" />
          <img src={heroImg2} alt="" className="w-full h-full rounded object-contain" />
          <img src={heroImg3} alt="" className="w-full h-full rounded object-contain" />
          <img src={heroImg4} alt="" className="w-full h-full rounded object-contain" />
          <img src={cariMg} alt="Car illustration" className="trackcar absolute transition-transform duration-[2000]" />
        </div> */}
      </main>

      <div id="services" className="how-it-works-section">
        <p className="how-it-works">How it works</p>
        <h2 className="how-it-works-header text-3xl sm:text-4xl md:text-5xl">
          We'll help you fuel your <br />{" "}
          <span className="automobile-journey">automobile journey </span>
        </h2>
      </div>

      <div className="grid xs:grid-cols-1 md:grid-cols-2 grid-cols-4 gap-5 mt-5">
        <div className="flex flex-col">
          <div className="timeline-step">
            <div className="timeline-box">1</div>
            <div className="timeline-line"></div>
          </div>
          <h3 className="step-instructions-header">Create an Account</h3>
          <p className="step-instructions-text">
            Create an account on our website to get started on your journey.
          </p>
        </div>
        <div className="flex flex-col">
          <div className="timeline-step">
            <div className="timeline-box">2</div>
            <div className="timeline-line"></div>
          </div>
          <h3 className="step-instructions-header">Request Consultation</h3>
          <p className="step-instructions-text">
            Fill a form and submit consultation request on RequestMechanic.
          </p>
        </div>
        <div className="flex flex-col">
          <div className="timeline-step">
            <div className="timeline-box">3</div>
            <div className="timeline-line"></div>
          </div>
          <h3 className="step-instructions-header">
            Virtual Video Consultation
          </h3>
          <p className="step-instructions-text">
            A one-on-one consultation with an automobile consultant from the
            comfort of your home.
          </p>
        </div>
        <div className="flex flex-col">
          <div className="timeline-step">
            <div className="timeline-box">4</div>
            <div className="timeline-line"></div>
          </div>
          <h3 className="step-instructions-header">Start Application</h3>
          <p className="step-instructions-text">
            Start your application and access a skilled mechanic nearby.
          </p>
        </div>
      </div>

      {/* Expert Guidance Section */}

      <section className="expert-guidance-section relative flex flex-col items-center justify-center my-24">
        <p className="expert-guidance-text text-center text-4xl sm:text-5xl md:text-6xl lg:text-7xl mb-10">
          Expert Guidance for All of your Automobile Troubles
        </p>
        <div className="CarFrame relative flex items-center justify-center">
          <img
            src={Showroom}
            alt=""
            className="max-w-full max-h-[70vh] object-contain"
          />
          <img
            src={ShowroomCar}
            alt=""
            className="animate-spin-slow max-h-[200px] sm:max-h-[400px] lg:max-h-[550px] object-contain absolute"
          />
        </div>
      </section>

      {/* Features Section */}
      <div id="features-section" className="discover-section my-20">
        <p className="expert-guidance-text text-center text-4xl sm:text-5xl md:text-6xl lg:text-7xl mb-10">
        Features for Connecting with Automechanics on RequestMechanic
        </p>

        <div className="Features-header-div p-4 grid md:grid-cols-1 lg:grid-cols-2 mt-5">
          <div className="featuresText-div flex flex-col justify-center items-center">
            <h1 className="text-white text-3xl md:text-4xl font-extrabold my-2 text-center">
             Find a Mechanic Near Me{" "}
            </h1>
            <p className="Advanced-AI-algorithms my-2 text-center max-w-[450px]">
            Advanced proximity based matching algorithms to help users find the
             most suitable mechanics based on their specific requirements, 
             location, and availability. Our personalized recommendations ensure
              users get the right expertise for their unique automotive needs.
            </p>
          </div>

          <img
            src={cultureNames}
            className="cultureNames w-full object-contain"
            alt="Culture Names"
          />
        </div>

        <div className="discover-grids flex flex-wrap justify-between mt-10 gap-10">
          <div className="discover-grid-box flex flex-grow flex-col justify-center gap-2 p-4 h-[300px]">
            <img
              src={featuresPhone}
              alt="Audio Call and Video Call"
              className="w-28 h-28"
            />
            <h3 className="features-sub-heading">Audio Call and Video Call</h3>
            <p className="features-sub-text max-w-[300px]">
              Seamless audio and video calling functionality allow users to
              connect with mechanics in real-time.
            </p>
          </div>
          <div className="discover-grid-box flex flex-grow flex-col justify-center gap-2 p-4 h-[300px]">
            <img
              src={featuresLock}
              alt="Secure Payment System"
              className="w-28 h-28"
            />
            <h3 className="features-sub-heading">Secure Payment System</h3>
            <p className="features-sub-text max-w-[300px]">
              You can easily pay for services rendered by mechanics through the
              app's internal wallet system.
            </p>
          </div>
          <div className="discover-grid-box flex flex-grow flex-col justify-center gap-2 p-4 h-[300px]">
            <img
              src={featuresSccop}
              alt="User-Friendly interface"
              className="w-28 h-28"
            />
            <h3 className="features-sub-heading">User-Friendly interface</h3>
            <p className="features-sub-text max-w-[300px]">
              RequestMechanic features an intuitive and user-friendly interface
              for easy navigation and seamless user experience
            </p>
          </div>
        </div>
      </div>

      {/* Support Section */}
      <section
        id="support"
        className="support-section flex flex-col sm:flex-row items-center justify-center sm:justify-between mt-12 sm:mt-24 lg:mt-32 gap-4 sm:gap-24"
      >
        <div className="support-div1 flex flex-col items-center gap-4 sm:w-1/2 sm:items-start">
          <h2 className="experience-seamless-car sm:text-3xl md:text-4xl">
            Experience Seamless Car Care and Real-Time Support with
            RequestMechanic
          </h2>
          <div className="input-button-div flex flex-col items-center sm:flex-row gap-4">
            <Link to={"/onboarding"}
              className="try-now-link"
            >
              <button
                id="join-button"
                type="submit"
                className="request-nav-button w-full sm:w-auto h-12 flex items-center justify-center px-3 text-sm"
                onClick={handleJoinWaitlist}
              >
                Try Now
                <svg
                  className="btn-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                </svg>
              </button>
            </Link>
          </div>
          {/* <div className="flex flex-col sm:flex-row gap-4 mt-6">
            <img src={GooglePlayCard} alt="Google Play Badge" />
            <img src={AppStoreCard} alt="App Store Badge" />
          </div> */}
        </div>

        <div className="support-div2 sm:w-1/2">
          <img src={phonePic} alt="Phone" className="phone-image" />
        </div>
      </section>

      <div className="relative grid grid-cols-1 md:grid-cols-2 my-20 items-center justify-between bg-yellow-50">
        <div className="p-10">
          <h2 className="text-center md:text-left text-3xl md:text-4xl lg:text-5xl text-green-900">
            The Power of Instant Automobile Service
          </h2>
          <div className="flex flex-column md:flex md:flex-row items-center gap-4 mt-4">
            <button onClick={()=>{document.getElementById("download").scrollIntoView({behavior: 'smooth',block:"center"})}} className="bg-green-600 flex items-center gap-2 rounded-lg text-sm text-white py-2 px-3">
              Download mobile app <FiArrowUpRight style={{ color: "white" }} />
            </button>
          </div>
        </div>
        <div className="hidden md:flex items-center justify-end h-full absolute right-0">
          <img
            src={MobileApp}
            alt="Mobile app"
            className="object-contain h-full"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 my-24">
        <div className="text-left">
          <h2 className="text-white font-bold mb-3 xs:text-3xl sm:text-4xl md:text-5xl">
            RequestMechanic is your automobile companion
          </h2>
          <p className="text-gray-400">
            This is where we have provided all the anwers to questions.{" "}
            <a
              href="mailto:support@requestmechanic.com"
              className="text-green-400"
            >
              Reach out
            </a>{" "}
            to us if you have any more questions.{" "}
          </p>
        </div>
        {/* FAQs section */}
        <Faq />
      </div>

      {/* Footer Section */}
      <footer className="bg-black text-white py-8 mt-32">
        <div className="grid gap-10 xs:grid-cols-1 sm:grid-cols-3 lg:grid-cols-4">
          {/* Footer Header */}
          <div className="sm:flex sm:flex-col sm:items-start">
            <img
              src={footerLogo}
              alt="Footer Logo"
              className="mb-4 cursor-pointer"
            />
            {/* <p className='footer-text'>RequestMechanic is a platform that <br/> provides you one-click instant access to a network of certified <br/> mechanics and auto-experts</p> */}
            <p className="footer-text">
              RequestMechanic is a mobile application that provides you
              one-click instant access to certified mechanics and auto-experts
            </p>
          </div>

          {/* Company */}
          <div className="sm:text-center flex flex-col sm:items-center">
            <p className="font-bold cursor-pointer">Company</p>
            <a
              href="#features-section"
              className="text-white underline-offset-1 text-xs mt-3 mb-2"
            >
              Features
            </a>
            <a
              href="https://medium.com/@ochuko_34353"
              className="text-white underline-offset-1 text-xs mt-3 mb-2" target="_blank"
            >
              Blog
            </a>
            <a
              href="#support-section"
              className="text-white underline-offset-1 text-xs my-2"
            >
              Support
            </a>


            <button
              onClick={(e) => openModal("pp",contractTypeConstant.Customer)}
              className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
              Customer's Privacy Policy
            </button>
            <button
              onClick={(e) => openModal("tnc",contractTypeConstant.Customer)}
              className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
              Customer's Terms & Conditions
            </button>
            <button
              onClick={(e) => openModal("pp",contractTypeConstant.Mechanic)}
              className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
              Mechanic's Privacy Policy
            </button>
            <button
              onClick={(e) => openModal("tnc",contractTypeConstant.Mechanic)}
              className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
              Mechanic's Terms & Conditions
            </button>
            <TermsModal
              isOpen={isModalOpen}
              onClose={closeModal}
              modalType={modalType}
              userType={contractType}
            />
          </div>

          {/* Legal */}
          {/* Uncomment this section if you have legal links */}
          {/* <div className="flex flex-col items-start sm:items-center mt-16 gap-8">
      <p className="font-bold cursor-pointer">Legal</p>
      <a href="/terms">Terms</a>
      <a href="/privacy">Privacy</a>
      <a href="/security">Security</a>
    </div> */}

          {/* Contact */}
          <div className="text-center flex flex-col items-center">
            <p className="font-bold cursor-pointer">Contact</p>
            <a
              id="support-section"
              href="mailto:Support@requestmechanic.com"
              className="email-link my-3"
            >
              <p className="email-link">Support@requestmechanic.com</p>
            </a>
            <div className="flex gap-8">
              <a href="https://www.facebook.com/profile.php?id=100095295696587&mibextid=LQQJ4d">
                <FiFacebook className="text-white text-xl cursor-pointer" />
              </a>
              <a href="https://twitter.com/requestmechanic?s=11">
                <FiTwitter className="text-white text-xl cursor-pointer" />
              </a>
              <a href="https://instagram.com/requestmechanic?igshid=MzRlODBiNWFlZA==">
                <FiInstagram className="text-white text-xl cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Home;

function PWAinstallBaner({deferredPrompt,closeFunc,setDeferredPrompt}){


  async function handleDownloadClick(){
    closeFunc();
    deferredPrompt.prompt();
    setDeferredPrompt(null);
  }

  return (
    <div className="border-solid border-[0.02rem] border-green-600 z-[15] w-[90vw] landscape:w-[50vw] fixed left-[5vw] landscape:left-[25vw] top-[50vh] flex flex-col items-center justify-center px-3 py-4 landscape:p-3 bg-slate-100 rounded-xl">
      <p className="text-center">For Ease of Use You can now download the RequestMechanic app on your device</p>
      <div className="pt-3 flex gap-6">
        <Button onClick={handleDownloadClick} variant="contained" sx={{ backgroundColor: "green" }}>Download</Button>
        <Button onClick={closeFunc} variant="contained" sx={{ backgroundColor: "grey" }}>Close</Button>
      </div>
    </div>
  );
}

function DownlaodDialouge ({support_check_timeout,setshowDownloadDiag}){
  return <div className=" border-solid border-[0.02rem] border-green-600 z-[15] w-[90vw] landscape:w-[50vw] fixed left-[5vw] landscape:left-[25vw] top-[50vh] flex flex-col  items-center justify-center px-3 py-4 landscape:p-3 bg-slate-100 rounded-xl ">
              {!support_check_timeout &&<p>Download File is Being Processed</p>}
              {support_check_timeout &&<p>Your device does not support this app or you have the app installed already</p>}
              {!support_check_timeout &&<div className="w-[70%]"><LinearProgress variant="indeterminate" sx={{color:"green",backgroundColor:"greenyellow"}} /></div>}
              <Button onClick={function(){setshowDownloadDiag(init=>false)}} >Close</Button>
  </div>
}
