import React, { useState, useEffect, useRef } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
}
from 'mdb-react-ui-kit';
import logoImg from '../Images/RM - Idan-03 1.svg';
import logoTxt from '../Images/Request Mechanic.png';
import {
  FiArrowRight,
} from "react-icons/fi";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';



const navlist = [
  {
    title: "Home",
    link: "/"
  },
  {
    title: "Service",
    link: "#services"
  },
  {
    title: "Support",
    link: "#support"
  },
  {
    title: "FAQs",
    link: "#FAQs"
  }
]

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

function Login({ setIsAuthenticated }) {
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(''); // State to store user's email


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);


const handleLogin = async () => {
  try {
    setLoading(true);

    const response = await axios.post(
      `${baseUrl}
/user/login/`,
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Response Status Code:', response.status);

    const responseData = response.data;
    console.log('Response Data:', responseData);

    if (response.status === 200) {
      const { data } = responseData;

      // Ensure that the tokens property is available before destructuring
      if (data.tokens) {
        const { refresh, access } = data.tokens;

        // Save the refresh token in local storage
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('authAccessToken', access);

                  // Save user's email in local storage and state
                  const userEmail = data.email || ''; // Replace 'email' with the actual key in your response
                  localStorage.setItem('userEmail', userEmail);
                  setUserEmail(userEmail);

        setIsAuthenticated(true);

        // Continue with your authentication logic (navigate, etc.)
        navigate('/dashboard');
      } else {
        setError('Tokens not found in the response data');
      }
    } else {
      setError(responseData.message || 'Invalid email or password');
    }
  } catch (error) {
    console.error('Login failed:', error);
    setError('Invalid email or password');
  } finally {
    setLoading(false);
  };
};

const handleKeyDown = (event) => {
  // Check if the pressed key is "Enter"
  if (event.key === 'Enter') {
    // Call the login function
    handleLogin();
  }
};
  
  


  return (
    <MDBContainer fluid className='App'>

      <nav className="fixed z-50 top-0 left-0 w-[100vw] bg-black px-[25px] sm:px-[50px] md:px-[100px] py-[30px] mb-[150px] flex flex-row items-center justify-between border-b border-b-gray-900">
              <div className="flex gap-10">
                {/* NAV LOGO */}
                <div className="flex gap-1">
                  <img className="object-contain h-8" src={logoImg} alt="Request Mechanic" />
                  <img className="object-contain h-8" src={logoTxt} alt="" />
                </div>
                {/* NAV LINKS */}
                <div className="hidden md:flex items-center gap-4">
                  {navlist.map((nav) => (
                      <a key={nav.title} href={nav.link} className="text-gray-400 hover:text-white text-sm no-underline">{nav.title}</a>
                  ))}
                </div>
              </div>
              {/* NAV CTA */}
              <a href="https://service.requestmechanic.com/form" className="request-nav-button flex items-center gap-2 text-black text-sm py-2 px-3 w-fit flex-grow-0" >
                Request Now
                <FiArrowRight className="FiArrowRight" style={{ color: "black", height: "20px", width: "20px" }} />
              </a>
      </nav>
      <MDBRow className='d-flex justify-content-center align-items-center h-screen'>
        <MDBCol col='12'>

          <MDBCard className='bg-white text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

=
              <p className="login-header text-black mb-5">Login to Access the Business Management Dashboard</p>

              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                labelClass="text-black"
                label="Email address"
                id="formControlLg"
                type="email"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4 mx-5 w-100"
                labelClass="text-black"
                label="Password"
                id="formControlLg"
                type="password"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

                  {/* Login Button */}
                  <button
                id='login-button'
                type='submit'
                className={`text-black hover:bg-gray-700 hover:text-white bg-purple-600 text-white px-6 py-3 rounded-md text-base font-semibold blur-hover ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onKeyDown={handleKeyDown}
                onClick={handleLogin}
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>

          <Link to="/reset-password/" className="text-black-50">
              <p className="small mb-3 pb-lg-2">Forgot password?</p>
          </Link>
              {/* Display error message if authentication fails */}
              {error && <p className="text-red-500">{error}</p>}


              {/* <div className='d-flex flex-row mt-3 mb-5'>
                <MDBBtn tag='a' color='none' className='m-3' style={{ color: '#fff' }}>
                  <MDBIcon fab icon='facebook-f' size="lg"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: '#fff' }}>
                  <MDBIcon fab icon='twitter' size="lg"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-3' style={{ color: '#fff' }}>
                  <MDBIcon fab icon='google' size="lg"/>
                </MDBBtn>
              </div> */}

            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
}

export default Login;