import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OnboardClientCard from "../Components/onboardClientCard";
import Button from "../Components/button";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TermsModal } from "../Components/modal/termsModal";
import SeoBasic from "../Components/seo"

const OnboardClient = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [contractType, setContractType] = useState("");

  const openModal = (mType, contractType) => {
    setIsModalOpen(true);
    setModalType(mType);
    setContractType((init) => contractType);

    if (mType === "pp") {
      navigate("#privacy-policy");
    } else if (mType === "tnc") {
      navigate("#terms-and-condition");
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType("");
    setContractType((init) => "");
    navigate(window.location.pathname);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#privacy-policy") {
      openModal("pp");
    } else if (hash === "#terms-and-condition") {
      openModal("tnc");
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
  };
  const client = [
    {
      text1: "Get expert",
      text2: "help anytime",
      text3: "Connect with certified mechanics",
      text4: "who can help diagnose and fix your",
      text5: "car problems quickly and easily.",
      image: "Group-83.png",
    },
    {
      text1: "Real-time",
      text2: "tech",
      text3: "Chat and video call with certified",
      text4: "mechanics for quick, real-time",
      text5: "help and advice.",
      image: "Group-84.png",
    },
    {
      text1: "Easy and",
      text2: "secure payments",
      text3: "Make payments directly within the",
      text4: "app using our secure payment",
      text5: "options and internal wallet system.",
      image: "Group-85.png",
    },
  ];

  return (
    <div className="">
      <SeoBasic title={"You are just a few steps from finding an auto mechanic near you"}
       canonHref={"onboarding"} 
       desc={"Sign up to get in contact with a requestmechanic screened auto mechanic nearby"} />
      <Slider {...sliderSettings}>
        {client.map((item, index) => (
          <div key={index} className="">
            <OnboardClientCard
              text1={item.text1}
              text2={item.text2}
              text3={item.text3}
              text4={item.text4}
              text5={item.text5}
              image={item.image}
            />
          </div>
        ))}
      </Slider>
      <div className="pt-10 flex flex-col items-center gap-y-5">
        <Link to="/create-account">
          <Button
            text="Create account"
            className="!min-w-[352.54px] text-white bg-greeen"
          />
        </Link>
        <Link to="/login-user">
          <Button
            text="Log In"
            className="!min-w-[352.54px] border-greeen border-2 bg-white text-greeen"
          />
        </Link>
      </div>
      <div className="text-xs sharp-sans text-center pt-4 pb-4">
        <p className="text-[#878C98]">
          By tapping Create an account and using{" "}
        </p>
        <p className="text-[#878C98]">
          RequestMechanic, you agree to our{" "}
          <span className="text-black cursor-pointer" onClick={() => setIsModalOpen(true)}>Terms</span> and{" "}
        </p>
        <p className="text-black" onClick={() => setIsModalOpen(true)}>Privacy Policy. </p>
      </div>
      <TermsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        modalType={modalType}
        userType={contractType}
      />
    </div>
  );
};

export default OnboardClient;
