
import { useSearchParams } from "react-router-dom"
import Calls from "./call"
import { useEffect } from "react";




export default function MechCall({carModel,customersEmail,customersName,id,callerToken,profile_pic,handleBack}){
    // const [searchParams,setSearchParams] = useSearchParams();
    
    async function hasCameraPermission(){
        return new Promise(function(resolve,reject){
            navigator.permissions.query({ name: "camera" })
            .then(function(result){
                resolve(result.state);
                return result;
            }).catch((error)=> {
                console.log(error);
                reject("an error occured")
            });
        })
    }

    function getCameraAndMicrophonePermision(){
                navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((stream) => {
                    console.log("Access granted to microphone and camera");
                })
                .catch((error) => {
                    alert("you can't make video calls without granting permission to use your microphone and camera")
                });
    }
    useEffect(function(){
        hasCameraPermission()
        .then(function(result){
            if(result !== "granted"){
                getCameraAndMicrophonePermision();
            }
        })
        .catch(function(error){
          console.log(error);
        },[])
        })


    return <div>
                <Calls carModel={carModel} customersEmail={customersEmail}
                customersName={customersName} id={id}
                profile_pic={profile_pic} callerToken={callerToken} handleBack={handleBack} />
    </div>
    
}