import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Wrench from "./wrenchsvg";
import { Head } from './transactionHistory';
import styled from 'styled-components';
import TableRowsSelect from './TableSelectRows';
import { Profile, User, Document,Call,Verify } from 'iconsax-react';

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

const Mechanics = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [registeredMechanics, setRegisteredMechanics] = useState([]);
  const [totalRegisteredMechanics, setTotalRegisteredMechanics] = useState(0);
  const [processedData, setProcessedData] = useState([]);
  const [mechanicsStats, setmechanicsStats] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get("limit")) || 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(
          `${baseUrl}
/user/users/mechanics/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("Registered Mechanics Response:", response.data);

          const mechanicsData = response.data?.data || [];
          setRegisteredMechanics(mechanicsData);
          setTotalRegisteredMechanics(mechanicsData.length);
        })
        .catch((error) => {
          console.error("Error fetching registered mechanics data:", error);
        });
    } else {
      console.error("Authentication credentials were not provided.");
    }
  }, []);
  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(
          `${baseUrl}/stats/mechanics_statistics/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("mechanics statistics Response:", response.data);
          setmechanicsStats(()=>response.data)
        })
        .catch((error) => {
          console.error("Error fetching mechanics statistics data:", error);
        });
    } else {
      console.error("Authentication credentials were not provided.");
    }
  }, []);

  useEffect(() => {
    if (registeredMechanics.length > 0) {
      const newData = registeredMechanics.filter((aRegisteredMechanic) => {
        return String(
          `${aRegisteredMechanic.first_name} ${aRegisteredMechanic.last_name} ${
            aRegisteredMechanic.email
          } ${
            aRegisteredMechanic.phone_number
          } ${aRegisteredMechanic.car_speciality
            .map((specialty) => specialty.name)
            .join(" ")}`
        )
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setProcessedData(newData);
    }
  }, [searchValue, registeredMechanics]);

  function handleSearchChange(value) {
    setSearchValue(value);
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // Calculate total pages
  const totalPages = Math.ceil(processedData?.length / limit);

  // Get the data for the current page
  const currentData = processedData.slice(
    (currentPage - 1) * limit,
    currentPage * limit
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ currentPage, limit: value });
    console.log(value);
  };

  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">
      <button className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </button>

      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
        <div className={`dashboard-content ${showSidebar ? "sidebar" : ""}`}>
          {showSidebar && (
            <button className="close-sidebar-button" onClick={toggleSidebar}>
              <FiX className="close-icon" />
            </button>
          )}
          <TopHeader userEmail={userEmail} />

          <div className="p-8">
            <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
              Users {">"} Mechanics
            </h2>
            <p className="dashboard-text mt-2 text-gray-400">
              Track and manage the total number of registered mechanics on the
              platform
            </p>
<div className=' text-blue-500' ></div>
          </div>
          <div className='w-full flex flex-col landscape:flex-row flex-wrap justify-around items-baseline landscape:mb-[2.5rem]'>            
              <CardStat data={totalRegisteredMechanics} desc={"Registered mechanics"} />
              <CardStat data={mechanicsStats?.mechanics_with_calls} desc={"Mechanics with calls"} tailColortext={"text-gray-600"} tailColorbg={"bg-gray-300"} Icon = {Call} />
              <CardStat data={mechanicsStats?.mechanics_with_documents} desc={"Mechanics with Documents"} tailColortext={"text-green-600"} tailColorbg={"bg-green-300"} Icon = {Document} />
              <CardStat data={mechanicsStats?.total_mechanics_approved} desc={"Approved Mechanics"} tailColortext={"text-blue-600"} tailColorbg={"bg-blue-300"} Icon = {Verify} />
          </div>


          <MechanicView>
            <div className="px-4">
              <Head
                title={"Users"}
                handleSearch={handleSearchChange}
                placeholder={
                  "Search Mechanic by Name, Email, speciality or Phone Number "
                }
              />
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-4 text-left">Mechanic Name</th>
                    <th className="p-4 text-left">Specialty</th>
                    <th className="p-4 text-left">Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((mechanic) => (
                  // {processedData.map((mechanic) => (
                    <tr key={mechanic.id} className="bg-white">
                      <td className="p-4">
                        {(mechanic.first_name || "Unknown") +
                          " " +
                          (mechanic.last_name || "")}
                      </td>
                      <td className="p-4">
                        {mechanic.car_speciality &&
                        mechanic.car_speciality.length > 0
                          ? mechanic.car_speciality
                              .map((specialty) => specialty.name)
                              .join(", ")
                          : "Unknown"}
                      </td>
                      <td className="p-4">
                        <Link
                          to={`/mechanics/${encodeURIComponent(
                            mechanic.email
                          )}`}
                          className="text-blue-500"
                        >
                          View Profile
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <PaginationView>
              <TableRowsSelect onChange={handleRowsPerPageChange} />

              <div className="paginate">
                <button
                  className="prev"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <span>
                  Page {currentPage} of {Math.ceil(processedData.length / limit)}
                </span>
                <button
                  className="next"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </PaginationView>
          </MechanicView>
        </div>
      </div>
    </div>
  );
};

function CardStat({data,desc,tailColortext,tailColorbg,Icon = false}){
  return  <div className="landscape:w-[20%]  dashboard-stats  mt-8 ">
            <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
              <div className={`dashboard-stat-icon ${Icon?`${tailColorbg}`:"bg-orange-300"} rounded-full p-3 w-fit flex items-center justify-center`}>
                {Icon ? <Icon className = {`text-3xl ${tailColortext}`} />: <Wrench className={`text-3xl text-orange-600`} />}
              </div>
              <h3 className="mt-2 text-sm font-semibold text-slate-500 block w-[90%] ">
                {desc}
              </h3>
              <p className="mt-2 text-2xl font-bold">
                {data}
              </p>
            </div>
</div>
}

export default Mechanics;

const MechanicView = styled.div`
  margin: 0 1rem;
  height: 100%;
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      background-color: rgb(209 213 219);
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: rgb(209 213 220);
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;