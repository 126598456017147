
import { NotificationsPageHeader, NotificationBody} from "./components"





export default function NotificationsPage(){
    return  <div className="w-screen h-screen ">
                    <NotificationsPageHeader/>
                    <NotificationBody  />
            </div>
}