import { useEffect, useState } from "react"
import { ArrowLeft } from "iconsax-react"



export function NotificationsPageHeader(){

    function handleBackClick(){
        window.history.back()
    }

    return <div className="flex gap-[25%] px-4 py-2 items-center">
                <div onClick={handleBackClick} >
                     <ArrowLeft/>
                </div>
                <h2 className="font-semibold text-2xl" >NOTIFICATIONS</h2>
    </div>
}


export function NotificationBody(){
    const [notifications , setNotifications] = useState([]);

    function notificationIsNotEmpty(){
        const numberOfNotifications = localStorage.getItem("notification-count");
        return numberOfNotifications !== null ? numberOfNotifications : 0;
    }

    function getNotification(){
        const number_of_notifications = notificationIsNotEmpty()
        if(number_of_notifications===0){
            return;
        }else{
            let notificationsData = [];
            for(let i =1 ; i <= number_of_notifications;i++){
                notificationsData.push(JSON.parse(localStorage.getItem(`notif_data_${i}`)))
            }
            console.log("these are the notifications data gotten",notificationsData);
            setNotifications(()=>notificationsData);
        }
    }
    
    useEffect(function(){
        getNotification()
    },[])

    return <div className="w-full px-4 flex flex-col gap-4 pt-8" >
                {(notifications.length < 1) && <NoNotifications/> }
                {(notifications.length > 0) && (
                    notifications.map(aNotification =>{
                        return <ANotificationItem title={aNotification?.notification?.title} summary={aNotification?.notification?.body} />
                    })
                ) }

            </div>
}

function ANotificationItem({title,summary}){
    return <div className="px-2 py-4 flex flex-col gap-2 border-solid border-green-700 border-[0.02rem] rounded-md">
                <p className=" text-green-600 font-coolvetica font-semibold text-ellipsis block w-full text-nowrap overflow-hidden ">{title}</p>
                <p className="text-sm text-ellipsis block w-full overflow-hidden">{summary}</p>
            </div>
}

function NoNotifications(){
    return <div className="flex items-center justify-center w-full h-[100%]">
                    <p className="font-dmsans">There are currently no notifications</p>
    </div>
}