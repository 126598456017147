import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FiMenu, FiX } from "react-icons/fi";
import axios from "axios";
import { Head } from "./transactionHistory";
import { Car } from "iconsax-react";
import { Link, useSearchParams } from 'react-router-dom';
import styled from "styled-components";
import TableRowsSelect from "./TableSelectRows";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

const CarOwners = () => {
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [registeredCarOwners, setRegisteredCarOwners] = useState([]);
  // const [CarOwnerStats, setCarOwnerStats] = useState();
  const [processedData, setProcessedData] = useState();
  const [searchValue, setSearchValue] = useState();
  const [ListOfCars, setListOfCars] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get("limit")) || 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    try {
      const accessToken = localStorage.getItem("authAccessToken");
      if (accessToken) {
        axios
          .get(`${baseUrl}/user/car-brands/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(function (result) {
            setListOfCars((init) => result?.data.data);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect(() => {
  //   const accessToken = localStorage.getItem("authAccessToken");

  //   if (accessToken) {
  //     axios
  //       .get(
  //         `${baseUrl}/stats/mechanics_statistics/`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log("mechanics statistics Response:", response.data);
  //         setCarOwnerStats(()=>response.data)
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching mechanics statistics data:", error);
  //       });
  //   } else {
  //     console.error("Authentication credentials were not provided.");
  //   }
  // }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  function handleSearchChange(value) {
    setSearchValue((init) => value);
  }

  useEffect(() => {
    const newData = registeredCarOwners?.filter(function (ACarOwner) {
      return String(
        `${ACarOwner.username} ${ACarOwner.email} ${ACarOwner.car_brand} ${ACarOwner.phone_number}`
      )
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase());
    });
    setProcessedData((init) => newData);
  }, [searchValue]);

  useEffect(() => {
    if (registeredCarOwners?.length > 0) {
      setProcessedData((init) => registeredCarOwners);
    }
  }, [registeredCarOwners]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("authAccessToken");
        if (!accessToken) {
          console.error("Authentication credentials were not provided.");
          setLoading(false);
          return;
        }

        const response = await axios.get(`${baseUrl}/user/users/car_owners/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("Registered Car Owners Response:", response.data);

        const registeredCarOwners = response.data.data || [];
        setRegisteredCarOwners(registeredCarOwners);
      } catch (error) {
        console.error("Error fetching registered car owners data:", error);

        if (error.response) {
          console.error(
            `Server error (Status Code: ${error.response.status})`,
            error.response.data
          );
        } else if (error.request) {
          console.error("Network error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate total pages
  const totalPages = Math.ceil(processedData?.length / limit);

  // Get the data for the current page
  const currentData = processedData?.slice(
    (currentPage - 1) * limit,
    currentPage * limit
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ currentPage, limit: value });
    console.log(value);
  };

  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">
      <div className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </div>

      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
        <div className={`dashboard-content ${showSidebar ? "sidebar" : ""}`}>
          {showSidebar && (
            <div className="close-sidebar-button" onClick={toggleSidebar}>
              <FiX className="close-icon" />
            </div>
          )}
          <TopHeader />

          <div className="p-8">
            <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
              Users {">"} Car Owners
            </h2>
            <p className="dashboard-text mt-2 text-gray-400">
              Track and manage the total number of registered car owners on the
              platform
            </p>
          </div>

          <div className='w-full flex flex-col landscape:flex-row'>
            <CardStat data={registeredCarOwners.length} desc={"Total Number of registered Car Owners"} />
            {/* <CardStat data={CarOwnerStats?.total_owners} desc={"Total Number of Car Owners"} /> */}
          </div>

          <CarOwnersView>
            <div className="px-4 py-2">
              <Head
                title={"Users"}
                handleSearch={handleSearchChange}
                placeholder={
                  "Search Car Owners by Name, Email, Phone Number or brand"
                }
              />
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-4">User Name</th>
                    <th className="p-4">Email</th>
                    <th className="p-4">Phone Number</th>
                    <th className="p-4">Car Details</th>
                    <th className="p-4">Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((registeredCarOwner) => (
                  // {processedData?.map((registeredCarOwner) => (
                    <tr
                      key={registeredCarOwner.id}
                      className="hover:bg-gray-100"
                    >
                      <td className="p-4">
                        <div className="flex items-center">
                          {registeredCarOwner.profile_pic && (
                            <img
                              src={registeredCarOwner.profile_pic}
                              alt="Profile"
                              className="w-8 h-8 rounded-full mr-2"
                            />
                          )}
                          <span>{registeredCarOwner.username}</span>
                        </div>
                      </td>
                      <td className="p-4">{registeredCarOwner.email}</td>
                      <td className="p-4">{registeredCarOwner.phone_number}</td>
                      <td className="p-4">
                        {ListOfCars?.find(function (aBrand) {
                          return aBrand.id === registeredCarOwner.car_brand;
                        })?.name || "N/A"}
                      </td>
                      <td className="p-4">
                        <Link
                          to={`/car-owner/${encodeURIComponent(
                            registeredCarOwner.email
                          )}`}
                          className="text-blue-500"
                        >
                          View Profile
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <PaginationView>
              <TableRowsSelect onChange={handleRowsPerPageChange} />

              <div className="paginate">
                <button
                  className="prev"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <span>
                  Page {currentPage} of{" "}
                  {Math.ceil(processedData?.length / limit)}
                </span>
                <button
                  className="next"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </PaginationView>
          </CarOwnersView>
        </div>
      </div>
    </div>
  );
};


function CardStat({data,desc,tailColor,Icon = false}){
  return  <div className="  dashboard-stats  mt-8 ml-8 mr-8">
            <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
              <div className={`dashboard-stat-icon bg-${Icon?tailColor:"green"}-200 rounded-full p-3 w-fit flex items-center justify-center`}>
                {Icon ? <Icon className = {`text-2xl text-${tailColor}-600`} />: <Car className={`text-2xl text-green-600`} />}
              </div>
              <h3 className="mt-2 text-sm font-semibold text-slate-500">
                {desc}
              </h3>
              <p className="mt-2 text-2xl font-bold">
                {data}
              </p>
            </div>
</div>
}

export default CarOwners;

const CarOwnersView = styled.div`
  margin: 0 1rem;
  height: 100%;
  padding-bottom: 2rem;
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      background-color: rgb(209 213 219);
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: rgb(209 213 220);
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;