import axios from 'axios';
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import icon from "./Images/logo192.png";

const firebaseConfig = {
  apiKey: "AIzaSyDLs8k7WzsUTLaqbHHG6TTrqb75gbYNhRg",
  authDomain: "request-mechanic.firebaseapp.com",
  projectId: "request-mechanic",
  storageBucket: "request-mechanic.appspot.com",
  messagingSenderId: "99259155360",
  appId: "1:99259155360:web:8c77726a03b8b5ab489ac5",
  measurementId: "G-ZZ0PWHPYQD"
};

let app;

if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
}

export const db = getFirestore(app);

let messaging;
isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(app);
    getToken(messaging, { vapidKey: "BIyETeKClKJtJuC3dQgfrjkEnvxmuC1d1w0T2WEYVtQyeHwxyxMuxqoAEdzBauIElj_njxjqusYzQDf_ZlUhh48" })
      .then(function(token) {
        console.log("FCM Token:", token);
        return token;
      }).catch(function(error) {
        console.log(error);
      });

    onMessage(messaging, (payload) => {
      console.log("notification payload", payload);

      if ('Notification' in window) {
        switch(true) {
          case (payload?.data.is_update === "True" ):{
            console.log("payload",payload)
            const updateEvent = new CustomEvent("update-notification-call",{detail:{...payload}});
            document.dispatchEvent(updateEvent);
          }
          break;
          case (payload?.data.is_call === "True" ) &&  (payload?.data.is_voice === "True"):{
            const d = payload.data;
            console.log("payload",payload)
            const voiceCallEvent = new CustomEvent("incoming-voice-call",{detail:{...payload}});
            document.dispatchEvent(voiceCallEvent)

          }
            break;
          case (payload?.data.is_call === "True" ) &&  (payload?.data.is_voice === "False"):{
            const videoCallEvent = new CustomEvent("incoming-video-call",{detail:{...payload}})
            document.dispatchEvent(videoCallEvent);
          }
            break;
          case payload.data?.is_cancelled_call ==="True":{
              const endCallEvent = new CustomEvent("canceled-call",{detail:{...payload}})
              document.dispatchEvent(endCallEvent);
          }
          break
          case (payload?.data.is_call === "False" ) &&  (payload?.data.is_voice === "False"):{
                  const reloadEvent = new CustomEvent("incoming-request-reload")
                    document.dispatchEvent(reloadEvent);
            }
            break;
          case payload.data?.rejected === "false":{
              const endCallButton = document.getElementById("cllender");
              endCallButton.click()
            }
            break
          default:{
            console.log("non conforming case default");
            console.log(payload);
            new Notification(payload.notification.title, { body: payload.notification.body, icon: icon });
          }
        }
      } else {
        console.error("This browser does not support notifications.");
      }
    });
  } else {
    console.error("This browser doesn't support the required APIs for Firebase messaging.");
  }
}).catch((error) => {
  console.error('Error checking if messaging is supported:', error);
});

export default firebase;
export { messaging };

function requestPermission() {
  console.log('Requesting permission...');
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    });
  } else {
    console.error("This browser does not support notifications.");
  }
}


function mockEvent(event,payload,time=3000){
  const reloadEvent = new CustomEvent(event,{detail:{...payload}})
  setTimeout(() => {
      document.dispatchEvent(reloadEvent);
  }, time);
}

// const placeHolder =  {
  
//   from: "99259155360",
//   messageId: "7842b9b5-b451-4d99-a568-c734a3c3cd46",
//   notification: {
//       title: "You Have a Job Request!",
//       body: "Mayceon Yunus is Requesting Your Service."
//   },
//   data: {
//       initiate_id: "",
//       ownerEmail: "",
//       owner_car_model: "Camry 2020",
//       owner_image: "",
//       is_voice: "False",
//       is_cancelled_call: "False",
//       call_token: "dQJbj_Jrh7t4_PYJTXnsqy:APA91bE2wsjYw6EP8wcyHpz7-VsvPt4uZVmme_yrJ3GWuSp1QeCw5pqpofX5OgZtPqyCJ4TeJOSmcMo5QQmacg9mAAC6Nlu_nP3S98oes8Li2UfWfXwv3Ro",
//       ownerId: "",
//       meeting_id: "",
//       owner_fcm: "cGJTxF8dSvClne1CjnXcwI:APA91bGb6SGOCCdfa1Mh4cCzm6OSbowsISlUtVXxfnrdnIUdawcQN1QcDdxM3VT5BuYZGambMBE-VFfe5ZmSEdbby1Tx4cr01Umcwj05z38adHgt1Lsf6Sg",
//       ownerName: "Mayceon Yunus",
//       email: "",
//       is_update: "185c28063f6cf22fa5af504731f2a36f0b349892a004e0db97f820a53d1c8d3fe1e67bc44eda6b5a834f4d39e1de8f3b83be72685610f5ed90c0d1a06ff21df3",
//       is_call: "False",
//       owner_car_brand: "Toyota"
//   }

// }

// mockEvent("update-notification-call",{detail:placeHolder},10000)

